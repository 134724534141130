.titan-header {
  padding: 0px 24px !important;
  background-color: color(background-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-down(sm) {
    padding-right: 16px;
  }
  &__left {
    display: flex;
    align-items: center;
    img {
      @include respond-down(sm) {
        width: auto;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    .request-consultation {
      border: 1px solid color(primary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding-left: 12px;
      padding-right: 12px;
      height: 44px;
      margin-right: 24px;
      font-size: 16px;
      font-weight: 600;
      @include respond-down(sm) {
        display: none;
      }
    }
  }
  &__toggle {
    color: white;
    font-size: 18px;
    &:hover,
    &:focus {
      color: white;
    }
    @include respond-down(sm) {
      display: none;
    }
    &.mobile {
      display: none;
      @include respond-down(sm) {
        display: block;
        margin-left: 32px;
        padding: 0;
        height: 28px;
      }
    }
  }
  &__logo {
    height: 28px;
    width: auto;
    object-fit: cover;
  }
}
