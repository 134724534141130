/* ==========================================================================
   # START DEFAULT SETTING
   ==========================================================================
*/
* {
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  word-break: break-word;
  font-family: 'Roboto', sans-serif;
  background-color: color(background-secondary);
  @include respond-down(sm) {
    overflow-x: hidden;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* ==========================================================================
 # END DEFAULT SETTING
 ==========================================================================
*/
.nowrap {
  white-space: nowrap;
}
