.titan-layout {
  &__content {
    background-color: color(background-primary);
    padding: 0px 24px 24px 24px;
    color: white;
    transition: ease all 0.4s;
    height: calc(100vh - 64px);
    overflow-y: auto;
    @include respond-down(sm) {
      margin-left: 0;
      padding: 16px;
    }
    .heading-block {
      margin-bottom: 16px;
      font-size: fontSize(heading-block, lg);
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      @include respond-down(sm) {
        font-size: fontSize(heading-block, sm);
        margin-bottom: 12px;
      }
    }
  }
}
.ant-layout {
  background-color: color(background-secondary);
  height: 100vh;
}

.titan-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__back {
    :hover {
      cursor: pointer;
    }
    padding: 5px 0px;
  }
  &__box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .actions {
      display: flex;
      align-items: center;
      .btn {
        margin-left: 16px;
      }
    }
    .actions-comp {
      width: 220px;
      display: flex;
      align-items: center;
      .btn {
        width: 100%;
        margin-left: 16px;
      }
    }
    .titan-page__title {
      margin-bottom: 0;
    }
  }
  &__title {
    color: color(text-grey-25);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
    @include respond-down(sm) {
      margin-bottom: 16px;
      font-size: fontSize(heading-block, sm);
    }
  }
  &__title-action {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-grey-400);
    }
    &-icon {
      width: 20px;
      height: 20px;
      background-color: color(text-grey-400);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      > svg {
        fill: color(text-grey-900);
      }
    }
  }
  &__action {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: color(primary);
    cursor: pointer;
  }

  &__content {
    padding: 24px 0px;
    background-color: color(background-primary);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @include respond-down(sm) {
      padding: 16px;
    }
    &__main {
      height: 100%;
      display: flex;
      flex-direction: column;
      @include respond-down(sm) {
        padding: 0;
      }
    }
  }
  &__block {
    padding: 24px;
    background-color: color(background-primary);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @include respond-down(sm) {
      padding: 16px;
    }
    &__main {
      margin-top: 24px;
      height: 100%;
      @include respond-down(sm) {
        margin-top: 16px;
      }
    }
  }
}
.titan-loading-circle {
  animation: circle 1.6s linear infinite;
}

.row-filter {
  margin-bottom: 24px;
  @include respond-down(sm) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 6px;
    @include customScrollTrack(6px, color(background-secondary), 6px);
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  &__item {
    padding: 4px 8px;
    border: 1px solid color(primary);
    border-radius: 1000px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: color(primary);
    margin-left: 6px;
    margin-right: 6px;
  }
}

.filter-btn-custom {
  position: relative;
  button {
    &:hover,
    &:focus {
      box-shadow: none;
      background-color: color(primary);
      color: color(text-color);
    }
  }
  .filter-date-picker {
    opacity: 0;
    width: 0;
    padding: 0;
    position: absolute;
    left: 0;
  }
  &.full {
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 44px;
      background-color: color(background-secondary-bold);
      color: white;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid transparent;
      outline: none;
      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
      // &.active {
      //   border: 1px solid color(primary);
      //   color: color(primary);
      //   svg {
      //     path {
      //       fill: color(primary) !important;
      //     }
      //   }
      //   &:hover {
      //     background-color: transparent;
      //     color: color(primary);
      //   }
      //   &:focus {
      //     background-color: transparent;
      //     color: color(primary);
      //   }
      // }
      // &.full-fill {
      //   background-color: color(primary);
      //   color: color(text-color);
      //   svg {
      //     path {
      //       fill: color(text-color);
      //     }
      //   }
      // }
    }
  }
  .ant-picker-range-separator {
    display: none;
  }
}
.box-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
