.statistic-card {
  padding: 20px;
  background-image: url(../../assets/img/background-card.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__amount {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0px;
  }
  &__items {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
