.user-detail-card {
  height: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  border: 1px;
  gap: 12px;
  background: color(text-grey-800);
  &__title {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: color(text-grey-400);
  }
  &__information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  &__promoted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    gap: 10px;
    border: 1px solid #f79009;
    background: linear-gradient(0deg, rgba(247, 144, 9, 0.1), rgba(247, 144, 9, 0.1));
    .promoted-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: color(text-grey-400);
    }
  }
}
