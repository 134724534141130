.btn {
  height: 44px;
  width: 128px;
  font-size: 16px;
  font-weight: 600;
  text-shadow: none;
  &--outline {
    background-color: transparent;
    color: color(primary);
    &:hover,
    &:focus {
      background-color: transparent;
      color: color(primary);
    }
  }
  &--fill {
    color: color(text-color);
    &:hover,
    &:focus {
      color: color(text-color);
    }
  }
  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 12px;
    }
  }
  &-w-auto {
    width: auto;
  }
  &--text {
    padding: 0;
    height: auto;
    background-color: transparent;
    color: color(primary);
    border: none;
    box-shadow: none;
    outline: none;
    span {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      color: color(primary);
    }
  }
  &-primary {
    color: color(white) !important;
    background: color(primary);
    &:hover,
    &:focus {
      color: color(white) !important;
      background: color(primary);
      border: 1px solid color(primary);
    }
  }
  &-orange {
    color: color(white) !important;
    background: color(warning-600);
    border: 1px solid color(warning-600);
    &:hover,
    &:focus {
      color: color(white) !important;
      background: color(warning-600);
      border: 1px solid color(white);
    }
  }
  &-purple {
    color: color(white) !important;
    background: color(purple-600);
    border: 1px solid color(purple-600);
    &:hover,
    &:focus {
      color: color(white) !important;
      background: color(purple-600);
      border: 1px solid color(purple-600);
    }
  }
  &-purple-ghost {
    color: color(purple-600);
    background: transparent;
    border: 1px solid color(purple-600);
    &:hover,
    &:focus {
      color: color(purple-600);
      background: transparent;
      border: 1px solid color(purple-600);
    }
  }

  &-filter {
    height: 44px;
    background: transparent;
    color: color(text-grey-400) !important;
    border: 1px solid color(text-grey-400);
    border-radius: 8px;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      background: transparent;
      border: 1px solid color(primary);
      color: color(primary);
    }
    > svg {
      margin-right: 5px;
    }
  }
}

.titan-switch {
  height: 24px;
  &:focus {
    box-shadow: none;
  }
  background-color: color(background-secondary-bold);
  .ant-switch-handle {
    top: 3px;
    &::before {
      background-color: color(text-default);
    }
  }
  &.ant-switch-checked {
    background-color: color(primary);
    &:focus {
      box-shadow: none;
    }
    .ant-switch-handle {
      top: 3px;
      &::before {
        background-color: white;
      }
    }
  }
}

.ant-btn-background-ghost {
  color: color(text-grey-400) !important;
  border: 1px solid color(text-grey-400) !important;
  &:hover,
  &:focus {
    background: transparent;
    border: 1px solid color(text-grey-400) !important;
    color: color(text-grey-400);
  }
}
.ant-btn-background-ghost[disabled] {
  color: color(text-grey-25) !important;
  background: #4d4d4d !important;
  border-color: #4d4d4d !important;
}
