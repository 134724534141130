.member-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  .data-info {
    background-color: color(text-grey-800);
    padding: 15px 64px 15px 64px;
    border-radius: 16px;
    display: flex;
    .member-amount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .member-type {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      .member-price {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 5px;
      }
      .time {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: color(text-grey-400);
      }
    }
    .info-item {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      padding: 10px 16px 10px 16px;
      border-radius: 8px;
      background-color: color(text-grey-900);
      &__noti {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &__data-empty {
    display: flex;
    align-items: center;
    padding: 24px;
    border-radius: 16px;
    gap: 24px;
    background: color(text-grey-800);
  }
}
